.onboardingContainer {
  background-color: #7048f4;
  //background-color: #b39ef9;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  margin: 0;
}

.onboardingCard {
  min-width: 350px;
  @media (min-width: 768px) {
    min-width: 400px;
    max-width: 400px;
  }
  //height: 80%;
}
