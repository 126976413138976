.EditTaskNotesModal {
  width: 90%;
  height: 90%;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
}
.card-content {
  overflow: auto;
  overflow-wrap: break-word;
}

.justify-end {
  justify-content: flex-end;
  margin-top: auto;
}
