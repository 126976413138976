.EditStepModal {
  &__card {
    width: 90%;
    max-width: 900px;
  }
}

.modal-background.focus.blue {
  background-color: #3483fa;
}
