.strategyCard {
  overflow: hidden;
  cursor: pointer;
}

.strategyCard:hover {
  background-color: gold;
}

.fa-heart:hover {
  color: red;
}

.StrategyBrowserContainer {
  overflow: auto;
}
