@charset "utf-8";

//BRAND COLORS
$primary: #22ced3;
$info: #3483fa;
$success: #7048f4;
$warning: #f4c648; //not custom
$danger: #d4145a;
$light: #fdf7fa;
$dark: #0f0b23;
$lightPurple: #b39ef9;
$darkPurple: #432b92;

.background-is-dark {
  background-color: #0f0b23;
}

$palettecolors: (
  "lightPurple": #b39ef9,
  "superLightPurple": #ebe6fd,
  "darkPurple": #432b92,
  "gray": gray,
  "green": green,
  "brown": brown,
  "red": red,
  "dark1": #2f2f4a,
  "light1": #ffffff,
  "dark2": #212745,
  "light2": #f1f3f7,
  "accent1": #45407e,
  "accent2": #5eccf3,
  "accent3": #a7ea52,
  "accent4": #5dceaf,
  "accent5": #ff8021,
  "accent6": #f14124,
  // blues
  //"themePrincipal": #7048f4,
  "themePrimary": #036ffc,
  "themeSecondary": #66a6fa,
  "themeTertiary": #accffc,
  "themteLight": #d7e6fa,
  "themeLighter": #deecf9,
  "themeLighterAlt": #eff6fc,
  "neutralLighter": #f3f2f1,
  "neutralLighterAlt": #faf9f8,
  "ghostwhite": #f8f8ff,
);

@each $name, $color in $palettecolors {
  .is-#{$name} {
    background-color: $color;
  }
}

@each $name, $color in $palettecolors {
  .is-fontcolor-#{$name} {
    color: $color;
  }
}

// COLORS
// $primary: #212745;
// $info: #45aaf2;
// $success: #23d160;
// $warning: #dba800;
// $danger: #fc5c65;
// $light: #f5f5f5;
// $dark: #363636;

// TEXT
$text: #4a4a4a;
$link: #3273dc;
$body-family: BlinkMacSystemFont, -apple-system, "Helvetica", "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", "Arial", sans-serif;
$title-family: false;
$button-family: false;

// BREAKPOINTS
$gap: 32px;
$tablet: 769px;
$desktop: 960px + (2 * $gap);
$widescreen: 1152px + (2 * $gap);
$fullhd: 1344px + (2 * $gap);
$widescreen-enabled: true;
$fullhd-enabled: false;

// LAYOUT
$section-padding: 3rem 1.5rem;
$section-padding-medium: 6rem 1.5rem;
$section-padding-large: 9rem 1.5rem;

// SPACING HELPERS (.mt-3, .mx-auto, .p-5, etc)
$spacing-shortcuts: (
  "margin": "m",
  "padding": "p",
);
$spacing-directions: (
  "top": "t",
  "right": "r",
  "bottom": "b",
  "left": "l",
);
$spacing-horizontal: "x";
$spacing-vertical: "y";
$spacing-values: (
  "0": 0,
  "1": 0.25rem,
  "2": 0.5rem,
  "3": 1rem,
  "4": 1.5rem,
  "5": 3rem,
  "auto": auto,
);

// SEE DOCS FOR MORE:
// https://bit.ly/30UvE5O

// IMPORT BULMA
@import "~bulma/bulma.sass";

body {
  // Fix Bulma bug where columns cause
  // horizontal scroll on mobile.
  // https://github.com/jgthms/bulma/issues/449
  overflow-x: hidden;
}

#root {
  // Full height of screen and flex so that we can push footer
  // to the bottom even if content is shorter than screen
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // Push footer to the bottom of the screen
  > .footer {
    margin-top: auto;
  }
  // Prevent child elements from shrinking when content
  // is taller than the screen (quirk of having flex parent)
  > * {
    flex-shrink: 0;
  }
}

// .mainAppContainer {
//   //display: flex;
//   margin-left: 80px;
// }

// #sidebar {
//   position: fixed;
// }

// .contentWithSidePanel {
//   //margin: 55px;
//   width: 70%;
//   padding: 10px;
// }

.sidePanelContainer {
  //   position: fixed;
  //   width: 28%;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   background: #212745;
  //   padding-top: 50px;
  border-left: 1px solid #dedede;
}

.breadcrumb {
  margin-left: 20px;
}

.rsc-os {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rsc-os-option-element {
  background-color: darkblue !important;
}

.is-urgent {
  color: red;
}

//Palette
// $palettecolors: (
//   "dark1": #000000,
//   "light1": #ffffff,
//   "dark2": #212745,
//   "light2": #b4dcfa,
//   "accent1": #4e67c8,
//   "accent2": #5eccf3,
//   "accent3": #a7ea52,
//   "accent4": #5dceaf,
//   "accent5": #ff8021,
//   "accent6": #f14124,
// );

.duckAvatarMessage {
  width: 35px;
  height: 40px;
  margin-right: 10px;
}
.modal {
  z-index: 9999;
}

.examples {
  background-color: #dfdfff;
  margin: 20px;
}
.recall {
  background-color: #dfffea;
}

.is-scrollable {
  overflow-y: scroll;
}

.rst__moveHandle {
  width: 22px;
  background-image: none;
  padding-top: 6px;
  text-align: center;
}

.rst__moveHandle::before {
  content: "\f142";
  font: normal normal normal 20px FontAwesome;
}

// Customize panel block selection/hover
a.panel-block,
label.panel-block {
  cursor: pointer;
  &:hover {
    background-color: #c7e0f4;
  }
}

.panel-block {
  &.is-active {
    color: #0078d4;
  }
}

.border-bottom {
  border-bottom: 2px solid #f5f5f5;
}

.wrap-button-text {
  height: max-content;
  white-space: normal;
}

.star-featured {
  color: #ff9800 !important;
}

.featured {
  background-color: #d7e6fa;
}
.recessed {
  background-color: #f4f2f0;
}

.striked-through {
  text-decoration: line-through;
}

.alarm-exclamation {
  color: #dc143c;
}

.fa-alarm-exclamation.obscured {
  color: gray;
}

.trophy {
  color: #ffa500;
}

.face-smile {
  color: #7048f4;
}
.face-expressionless {
  color: gray;
}
.face-confused {
  color: orange;
}
.face-frown {
  color: brown;
}

.water {
  color: #23c1da;
}
.taskTableHeader {
  vertical-align: middle;
  text-align: center !important;
  overflow-wrap: break-word;
}

.taskNameBar {
  padding: 1rem;
  background-color: #dedede;
}

.obscured {
  color: #a19f9d !important;
}

.autoWidth {
  width: auto !important;
}

.overflowHidden {
  overflow: hidden;
}

.lightPurpleContainer {
  background-color: $lightPurple;
}

.mobile-only {
  @media (min-width: 768px) {
    display: none;
  }
}

.quick-feedback {
  min-width: 350px;

  // for desktop make bigger and right docked
  @media (min-width: 768px) {
    min-width: 500px;
    left: auto;
    right: 0;
  }
}

.pointable {
  cursor: pointer;
}

.boxStepSubtitle {
  color: $darkPurple;
  font-weight: 400;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.smallPadd {
  padding: 10px;
}
