.BrowseTemplatesModal {
  &__card {
    width: 90%;
    height: 90%;
    max-width: 1300px;
    display: flex;
    flex-direction: column;
  }
}

.justify-end {
  justify-content: flex-end;
  margin-top: auto;
}
