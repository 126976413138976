.PricingSection {
  &__column {
    display: flex;
    align-items: stretch;
  }

  &__card {
    display: flex;
    // Stretch to fit column width
    width: 100%;
    // Ensure .card-content stretches to fit width
    align-items: stretch;
  }

  &__card-content {
    // Flex so that button can position self at
    // bottom of card using margin-top auto.
    display: flex;
    flex-direction: column;
    // Stretch to fit column width
    width: 100%;
    padding: 2rem;
  }

  &__name {
    font-size: 1.25rem;
  }

  &__price {
    margin: 20px 0;
  }

  &__period {
    opacity: 0.4;
  }

  &__description {
    margin-bottom: 1.6rem;
  }

  &__perks {
    margin-bottom: 1.6rem;

    li {
      margin: 0.7rem 0;
    }

    i {
      margin-right: 0.8rem;
    }
  }

  &__button {
    margin-top: auto;
  }
}
.ribbon {
  width: 160px;
  height: 32px;
  font-size: 12px;
  text-align: center;
  color: #0f0b23;
  font-weight: bold;
  box-shadow: 0px 2px 3px rgba(136, 136, 136, 0.25);
  background: #22ced3;
  transform: rotate(45deg);
  position: absolute;
  right: -42px;
  top: 20px;
  padding-top: 7px;
}
