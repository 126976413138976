.FaqSection {
  &__faq-item {
    cursor: pointer;
    padding: 1.6rem 0;
    border-bottom: 1px solid #efefef;
    &:last-child {
      border-bottom: none;
    }
  }

  &__icon {
    margin-right: 1rem;
  }
}
