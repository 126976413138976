.FocusMotivationModal {
  &__card {
    width: 90%;
    max-width: 900px;
  }
}

.modal-background.focus.motivation {
  background-color: #d4145a;
}
