.SuggestFeelingModal {
  &__card {
    width: 50%;
    height: 50%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}

.justify-end {
  justify-content: flex-end;
  margin-top: auto;
}
