.FocusThoughtModal {
  &__card {
    width: 90%;
    max-width: 900px;
  }
}

.modal-background.focus.thought {
  background-color: #7048f4;
}
