@import "bulma/sass/utilities/mixins";
$breakpoint: 1024px;

.bkg {
  margin: 0 auto;
}

.topContainer {
  @include from($breakpoint) {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
.videoSectionContainer {
  flex-direction: row;
}

.right-bkg {
  @include from($breakpoint) {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 100px !important;
    padding-bottom: 100px !important;
    margin-bottom: 100px !important;
    background: linear-gradient(
      270deg,
      rgb(248, 248, 249) 0%,
      rgb(248, 248, 249) 50%,
      rgb(255, 255, 255) 50%,
      rgb(255, 255, 255) 100%
    );
  }
}

.left-bkg {
  @include from($breakpoint) {
    display: flex;
    flex-direction: row;
    padding-top: 100px !important;
    padding-bottom: 100px !important;
    margin-bottom: 100px !important;
    background: linear-gradient(
      270deg,
      rgb(255, 255, 255) 0%,
      rgb(255, 255, 255) 50%,
      rgb(248, 248, 249) 50%,
      rgb(248, 248, 249) 100%
    );
  }
}

.videoPart {
  @include from($breakpoint) {
    width: 66.66%;
  }
}

.textPart {
  @include from($breakpoint) {
    width: 33.33%;
    margin-left: 20px;
  }
}

.sectionHeading {
  margin: 2rem 0px 1rem;
  font-family: proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1.2;
  color: rgb(40, 40, 40);
}

.sectionMain {
  font-family: proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: -0.0125rem;
  font-weight: 700;
  color: rgb(40, 40, 40);
  font-size: 2.5rem;
  line-height: 1.1;
}

.boxVideo {
  box-shadow: rgba(119, 119, 119, 0.6) 0px 5px 25px -10px;
  border-radius: 9px;
}

.boxShot {
  box-shadow: rgba(119, 119, 119, 0.6) 0px 5px 25px -10px;
  border-radius: 9px;
}

.underlining {
  position: relative;
  bottom: 25px;
  left: 0px;
  width: 55%;
  min-height: 0px;
  overflow: visible;
  pointer-events: none;
  z-index: 1;
}

.signupInput {
  height: 100%;
  min-width: 250px;
}

.imageCarousel {
  height: auto;
  margin: 1rem;
}

.email {
  min-width: 150px;
  max-width: 200px;
}

.signup {
  flex-wrap: wrap;
}
